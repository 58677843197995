import React from "react"
import { Icon } from "../../icons/Icon"

interface Props {
  locked: string
}

export const LockWarning: React.FunctionComponent<Props> = ({
  locked
}) => {
  return (
  <>
  <div style={{width: '100%', display: 'flex' , alignItems: 'center', justifyContent: 'center'}}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: 'gray', textAlign: 'center', fontSize: 'large', height: '3.4rem', padding: '1rem 2rem', borderRadius: '6px', backgroundColor: '#ffc7ca', minWidth: '50%', margin: '1rem 0'}}>
      <Icon icon="info" align="start" size="x-small" color="grey400" marginRight="2rem"></Icon>
      <em>Työ on lukittu käyttäjälle {locked}</em>
    </div>
  </div>
  </>
  )
}