import React, { useState, useEffect } from 'react'
import { TowingRecordRouteType } from '../../../common/types'
import { Page } from '../../../components/layout'
import { dismissNotifications } from '../../../components/notification'
import { useHistoryPush } from '../../../components/router'
import { CancelOrEndTowingRecord } from '../components/CancelTowingRecord'
import { EndTowingRecord } from '../components/EndTowingRecord'
import { Routes } from '../components/routes'
import { EditRouteSubforms } from '../components/routes/types'
import { TowingRecord } from './types'
import { ViewTowingRecordContent } from './ViewTowingRecordContent'
import { maybeStartTrackingRecord } from '../../../components/TrackedJobs/util'
import styled from 'styled-components'
import VehicleSearchModal from '../../../components/VehicleSearchModal'
import { useApolloClient } from 'react-apollo'
import { getStoredCompanyId } from '../../../graphql-client/storedState'
import { useSocket } from '../../../util/useSocket'

interface Props {
  queryResult: TowingRecord
  refetchData?: () => void
  action?: string | undefined
}

export const ViewTowingRecordPage: React.FunctionComponent<Props> = ({ queryResult, action, refetchData }) => {
  const initialSubForm: EditRouteSubforms = action === 'start' ? TowingRecordRouteType.start : 'view-towing-record'
  const [subformId, setSubformId] = useState<EditRouteSubforms>(initialSubForm)
  const [routeDone, setRouteDone] = useState<boolean>(false)
  const [towingRecord, setTowingRecord] = useState<TowingRecord>(queryResult)
  const [sybFormType, setsybFormType] = useState<'end'|'cancel'>('end')
  const historyPush = useHistoryPush()
  const client = useApolloClient()

  const scrollTop = () => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }
  const selectSubform = (id: EditRouteSubforms, done?: boolean) => {
    dismissNotifications()
    if (typeof done === 'boolean') {
      setRouteDone(done)
    }
    setSubformId(id)
    scrollTop()
  }

  const setTowingRecordState = (tr: TowingRecord, view?: EditRouteSubforms) => {
    setTowingRecord(tr)
    if (view) {
      selectSubform(view)
    }
  }
  const setNextForm = (id: EditRouteSubforms, type: 'end'|'cancel') => {
  
    setsybFormType(type)

    setSubformId(id)
    scrollTop()
  }

  const handleVehicleInfoLinkClick = () => {
    if (towingRecord.vehicleInfoCheck && towingRecord.vehicleInfoCheck.id) {
      selectSubform('view-vehicle-info')
    } else {
      historyPush(`/towing-record/${towingRecord.id}/edit/atj`)
    }
  }

  const showViewTowingRecord = subformId === 'view-towing-record'
  const showEditRoutes = subformId && ['start', 'arrival', 'finish', 'end'].includes(subformId)
  const showEndForm = subformId === 'end-towing-record'
  const showCancelOrEndForm = subformId === 'cancel-or-end-towing-record'
  const showVehicleInfo = subformId === 'view-vehicle-info'

  useEffect(() => {
    maybeStartTrackingRecord(towingRecord)
  }, [towingRecord])

  const socket = useSocket();
  
  useEffect(()=>{
    const companyId = getStoredCompanyId();
    if(!companyId){
      return;
    }
    
    const unlockedCallback = () => {
      if(!!queryResult.locked){
        refetchData && refetchData();
      }
    }

    // socket.on(`company-${companyId}.towing_record-${queryResult.id}.locked`, lockedCallback);
    socket.once(`company-${companyId}.towing_record-${queryResult.id}.unlocked`, unlockedCallback);
    return ()=>{
      console.log('removing event listeners from towing order');
      // socket.removeEventListener(`company-${companyId}.towing_record-${queryResult.id}.locked`, lockedCallback);
      socket.removeEventListener(`company-${companyId}.towing_record-${queryResult.id}.unlocked`, unlockedCallback);
    }
  },[socket, refetchData, queryResult]);

  console.log('towingRecord', towingRecord)

  return (
    <Page noPadding>
      {towingRecord.jobStatus !== 'cancelled' && towingRecord.sosServiceOrder && towingRecord.sosServiceOrder.orderStatus === 'cancelled_by_sos' 
        ? (<WarningContainer>
              <h2>Tilaus on peruttu. Lopeta työ. </h2>
              {/* <Button category='new' label='Viimeistele' onClick={() => {
                      selectSubform('cancel-towing-record', false)
                      setShowWarnings(false) 
                    }} />  */}
            </WarningContainer>) 
        : null}
      {showViewTowingRecord && (
        <ViewTowingRecordContent
          towingRecord={towingRecord}
          onEditAction={() => historyPush(`/towing-record/${towingRecord.id}/edit`)}
          onSelectSubform={(id, done) => selectSubform(id, done)}
          onVehicleInfoLinkClick={handleVehicleInfoLinkClick}
          locked={!!queryResult.locked}
          lockedFor={queryResult.locked}
        />
      )}
      {showEditRoutes && !queryResult.locked && (
        <Routes
          towingRecord={towingRecord}
          onCancel={() => setSubformId('view-towing-record')}
          subformId={subformId}
          routeDone={routeDone}
          onRefetch={refetchData}
          setTowingRecord={setTowingRecordState}
        />
      )}

      {showEndForm && !queryResult.locked && (
        <EndTowingRecord
          towingRecord={towingRecord}
          onCancel={() => setSubformId(`view-towing-record`)}
          //onSelectCancelForm={() => setSubformId('cancel-towing-record')}
          //setTowingRecord={setTowingRecordState}
          setNextForm={setNextForm}
        />
      )}
      {/* EndTowingRecord stays ruffly the same but the CancelTowingRecord form will become a form for both end and cancel 
       - Need to pass EndTowingRecord a function that gets the subformId and cancel/end - parameter
       - Need to pass CancelTowingRecord a function that gets the subformId (EndTowingRecord) and resets cancel/end - parameter to null??
       - Queries from EndTowingRecord need to be transfered to CancelTowingRecord so it can perform both operations 
       */}

      {showCancelOrEndForm && !queryResult.locked && (
        <CancelOrEndTowingRecord 
          subFormType={sybFormType} 
          towingRecord={towingRecord} 
          setTowingRecord={setTowingRecordState}
          onCancel={() => setSubformId('end-towing-record')} 
          />
      )}

      {showVehicleInfo && !queryResult.locked && (
        <VehicleSearchModal
          vehicleRegistration={towingRecord.vehicleDetails.registrationNumber}
          vehicleInfoCheckId={
            towingRecord.vehicleInfoCheck && towingRecord.vehicleInfoCheck.id ? towingRecord.vehicleInfoCheck.id : null
          }
          close={() => setSubformId('view-towing-record')}
          client={client}
        />
      )}
    </Page>
  )
}

interface WarningContainerProps {
  background?: string
}

export const WarningContainer = styled.div<WarningContainerProps>`
  top: 0;
  left: 0;
  position: sticky;
  width: 100%;
  z-index: 9;
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.theme.colors.red100};
`
