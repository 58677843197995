import React, { useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

import { getTowingRecordActions } from '../../../common/AccessControl'
import { towingRecordServiceTypeOptions } from '../../../common/options'
import { TypeIdentifier } from '../../../common/types'
import { Button } from '../../../components/Button'
import { getStatus } from '../../../components/JobStatus'
import { getDeliverable, getJobTypeDescription } from '../../../components/JobTypeDescription'
import { Text } from '../../../components/layout'
import { BigButtonSection } from '../../../components/layout/buttonlayout'
import { useHistoryPush } from '../../../components/router'
import { Styling } from '../../../components/Styling'
import { TopNavigation } from '../../../components/TopNavigation/TopNavigation'
import { SummaryHeader } from '../../../components/TowingCommon/SummaryHeader'
import { trackedSosStatuses, trackedStatuses } from '../../../components/TrackedJobs/types'
import { StateStore } from '../../../containers/StoreProvider'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'
import { createRouteMenuConfig, finishRouteAddressRequired } from '../components/routes/createRouteMenuConfig'
import { RoutesMenu } from '../components/routes/RoutesMenu'
import { EditRouteSubforms } from '../components/routes/types'
import { SummaryBody } from '../components/SummaryBody'
import { TowingRecord } from './types'
import Maybe from 'graphql/tsutils/Maybe'
import { LockWarning } from '../../../components/TowingCommon/LockWarning'

const Tracking = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`

const TrackingLoader = styled(Loader)`
  margin-top: 3px;
  margin-right: 5px;
`
interface Props {
  towingRecord: TowingRecord
  onEditAction: () => void
  onSelectSubform: (id: EditRouteSubforms, done: boolean) => void
  onRefetch?: () => void
  onVehicleInfoLinkClick: () => void
  locked?: boolean
  lockedFor: Maybe<string>
}

const getSlug = (towingRecord: TowingRecord): string => {
  const completeStatuses = ['payment_undefined', 'aggregate', 'complete', 'billing']

  return towingRecord && towingRecord.status && completeStatuses.includes(towingRecord.status) ? 'completed' : ''
}

const ViewTowingRecordContentIntl: React.FunctionComponent<Props & InjectedIntlProps> = ({
  intl,
  onEditAction,
  towingRecord,
  onSelectSubform,
  onVehicleInfoLinkClick,
  locked,
  lockedFor
}) => {
  const { formatMessage } = intl
  const { state } = useContext(StateStore)

  const routeMenuConfig = createRouteMenuConfig(
    towingRecord.routes,
    finishRouteAddressRequired(towingRecord.jobDetails.serviceType, towingRecord.type),
    towingRecord.type
  )

  const slug: string = getSlug(towingRecord)
  const { useJobButtons, editRoutes } = getTowingRecordActions(state.currentUser, towingRecord)

  const historyPush = useHistoryPush()
  return (
    <>
      <TopNavigation editAction={onEditAction} slug={slug} editAddress={`/towing-record/${towingRecord.id}/edit`} disableEdit={locked}/>
      <SummaryHeader
        jobTypeDescription={getJobTypeDescription(
          intl,
          towingRecord.jobDetails.serviceType,
          getStationByIdFromSettings(towingRecord.stationId, state.settings),
          towingRecord.label,
          towingRecord.typeIdentifier,
          getDeliverable(towingRecord.sosServiceOrder)
        )}
        status={getStatus(
          intl,
          towingRecord.status,
          getOperatorLabelFromId(
            towingRecord.operatorId,
            state.currentUser,
            state.settings ? state.settings.operators : null
          )
        )}
        type={formatMessage({ id: towingRecordServiceTypeOptions.options[towingRecord.jobDetails.serviceType] })}
        station={getStationByIdFromSettings(towingRecord.stationId, state.settings)}
        vehicleDetails={towingRecord.vehicleDetails}
        towingDate={towingRecord.jobDetails.towingDate}
      />
      {lockedFor ? (<LockWarning locked={lockedFor}></LockWarning>) : null}
      {((towingRecord.typeIdentifier === TypeIdentifier.sos && trackedSosStatuses.includes(towingRecord.jobStatus)) ||
        (towingRecord.typeIdentifier === TypeIdentifier.autoliitto &&
          trackedStatuses.includes(towingRecord.jobStatus))) && (
        <>
          <Tracking>
            <TrackingLoader type="Rings" color="black" width={25} height={25} />
            {towingRecord.typeIdentifier === TypeIdentifier.autoliitto && (
              <Text>Sijaintia lähetetään Autoliitolle</Text>
            )}
            {towingRecord.typeIdentifier === TypeIdentifier.sos && <Text>Sijaintia lähetetään SOS:ille</Text>}
          </Tracking>
        </>
      )}
      {!locked && (<RoutesMenu
        onClick={onSelectSubform}
        routes={routeMenuConfig}
        towingRecord={towingRecord}
        user={state.currentUser}
        canEditRoutes={editRoutes}
      />)}
      {useJobButtons && !locked && (
        <Styling padding="0 2rem" paddingMobile="0 0.5rem">
          <BigButtonSection>
            <Button
              category="on"
              label="Lopeta työ"
              onClick={() => {
                onSelectSubform('end-towing-record', false)
              }}
              size="m"
              fontWeight="normal"
              maxWidth="100%"
            />
            <Button
              category="on"
              label="Siirry allekirjoitukseen"
              mobileLabel="Allekirjoitus"
              size="m"
              fontWeight="normal"
              maxWidth="100%"
              onClick={() => historyPush(`/towing-record/${towingRecord.id}/edit/payment`)}
            />
          </BigButtonSection>
        </Styling>
      )}
      <SummaryBody
        towingRecord={towingRecord}
        onVehicleInfoLinkClick={onVehicleInfoLinkClick}
        vehicleInfoCheck={towingRecord.vehicleInfoCheck}
      />
    </>
  )
}

export const ViewTowingRecordContent = injectIntl(ViewTowingRecordContentIntl)

// function hideJobButtons(user: Maybe<User>, towingRecord: TowingRecord): boolean {
//   if (!user) {
//     return false
//   }

//   return shouldDisableAll(user, towingRecord)
// }
