import React, { useContext, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import Linkify from 'react-linkify'

import { towingServiceTypeOptions } from '../../../common/options'
import { Maybe, TowingOrderStatus, TowingOrderType, TypeIdentifier, User } from '../../../common/types'
import { getStatus } from '../../../components/JobStatus'
import { getDeliverable, getJobTypeDescription } from '../../../components/JobTypeDescription'
import { Page, Section, SectionTextColumn, Text } from '../../../components/layout'
import { SectionBody, SectionHeader } from '../../../components/layout/section'
import LinkButton from '../../../components/LinkButton'
import { Modal } from '../../../components/Modal'
import { parseAddress } from '../../../components/Parsers'
import { PhoneNumber } from '../../../components/PhoneNumber'
import { useHistoryPush } from '../../../components/router'
import { Styling } from '../../../components/Styling'
import { TopNavigation } from '../../../components/TopNavigation/TopNavigation'
import { buildJobDetails } from '../../../components/TowingCommon/JobDetails'
import { SummaryHeader } from '../../../components/TowingCommon/SummaryHeader'
import VehicleSearchModal from '../../../components/VehicleSearchModal'
import {
  transformDrive,
  transformFuel,
  transformTransmission,
  transformVehicleClass,
} from '../../../components/VehicleSearchModal/transformers'
import { StateStore } from '../../../containers/StoreProvider'
import { toMultilineText } from '../../../util/common'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'
import { RejectTowingOrder } from '../components/RejectOrder'
import { RejectType } from '../components/RejectOrder/types'
import { messages } from '../messages'
import { StatusChangeButtons } from './StatusChangeButtons'
import { TowingOrder, TowingOrderView } from './types'
import { LockWarning } from '../../../components/TowingCommon/LockWarning'
import { getRepairShop } from './getRepairShop'

interface TowingOrderFormProps {
  towingOrder?: TowingOrder
  refetchData: () => void
}

export const ViewTowingOrderContentIntl: React.FunctionComponent<TowingOrderFormProps & InjectedIntlProps> = ({
  intl,
  refetchData,
  towingOrder,
}) => {
  const { formatMessage } = intl
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [view, setView] = useState<TowingOrderView>('view-towing-order')
  const [rejectType, setRejectType] = useState<RejectType>(null)
  const historyPush = useHistoryPush()
  const client = useApolloClient()
  const { state } = useContext(StateStore)

  if (!towingOrder) {
    return null
  }

  const allowEdit = (user: Maybe<User>) => {
    if (!user) {
      return false
    }
    return true
  }

  const showStatusButtons = allowEdit(state.currentUser)
  const showEditButton = towingOrder.status !== TowingOrderStatus.pending_acceptance

  const showTowingOrder = view === 'view-towing-order'
  const showRejectModal = view === 'view-reject-modal'
  const showVehicleInfo = view === 'view-vehicle-info'

  const repairShop = getRepairShop(towingOrder)

  const setRejectView = (type: RejectType) => {
    setRejectType(type)
    setView('view-reject-modal')
  }

  const handleVehicleInfoLinkClick = () => {
    if (towingOrder.vehicleInfoCheckId) {
      setView('view-vehicle-info')
    } else {
      historyPush(`/towing-order/${towingOrder.id}/edit/atj`)
    }
  }

  const vehicleInfoCheck = towingOrder.vehicleInfoCheck
  //const typeId = towingOrder?.sosServiceOrder?.originatorApp === "LahiTapiola" ? "lahitapiola" : towingOrder?.typeIdentifier;
  console.log(towingOrder)
  return (
    <Page noPadding>
      {showRejectModal && (
        <RejectTowingOrder
          onClose={() => setView('view-towing-order')}
          towingOrder={towingOrder}
          rejectType={rejectType}
        />
      )}

      {showVehicleInfo && (
        <VehicleSearchModal
          vehicleRegistration={towingOrder.vehicleDetails.registrationNumber}
          vehicleInfoCheckId={towingOrder.vehicleInfoCheckId}
          close={() => setView('view-towing-order')}
          client={client}
        />
      )}

      {showTowingOrder && (
        <>
          {modalVisibility && (
            <Modal label="Tilan päivitys" message={modalMessage} close={() => setModalVisibility(false)} />
          )}
          <TopNavigation
            address="/towing-jobs"
            label="Työt"
            editAction={showEditButton ? () => historyPush(`/towing-order/${towingOrder.id}/edit`) : undefined}
            editAddress={`/towing-order/${towingOrder.id}/edit`}
            // editAddress={showEditButton ? `/towing-order/${towingOrder.id}/edit` : undefined}
            editLabel="Muokkaa"
            disableEdit={!!towingOrder.locked}
          />
          <SummaryHeader
            status={getStatus(
              intl,
              towingOrder.status,
              getOperatorLabelFromId(
                towingOrder.operatorId,
                state.currentUser,
                state.settings ? state.settings.operators : null
              ),
              towingOrder.visibility
            )}
            jobTypeDescription={getJobTypeDescription(
              intl,
              towingOrder.jobDetails.serviceType,
              getStationByIdFromSettings(towingOrder.stationId, state.settings),
              towingOrder.label,
              towingOrder.typeIdentifier,
              getDeliverable(towingOrder.sosServiceOrder)
            )}
            type={
              towingOrder.jobDetails.serviceType
                ? formatMessage({ id: towingServiceTypeOptions.options[towingOrder.jobDetails.serviceType] })
                : null
            }
            station={getStationByIdFromSettings(towingOrder.stationId, state.settings)}
            vehicleDetails={towingOrder.vehicleDetails}
            jobDetails={towingOrder.jobDetails}
            towingOrder={towingOrder}
            reFetchData={refetchData}
          />
          {towingOrder.locked && <LockWarning locked={towingOrder.locked} />}
          <Styling padding="0 2rem" paddingMobile="0.5rem" spacing="extra">
            {showStatusButtons && !towingOrder.locked && (
              <StatusChangeButtons
                status={towingOrder.status}
                type={towingOrder.type as TowingOrderType}
                towingOrderId={towingOrder.id}
                handleSuccess={(msg: string) => {
                  setModalMessage(msg)
                  refetchData()
                }}
                handleError={(msg: string) => {
                  setModalMessage(`Tilan päivitys epäonnistui: ${msg}`)
                  setModalVisibility(true)
                }}
                openRejectPage={setRejectView}
                towingOrder={towingOrder}
              />
            )}
          </Styling>
          <Styling padding="0 2rem" paddingMobile="0 0.5rem">
            <Section gap="2rem" justify="text" columns={2}>
              <SectionTextColumn width="100%">
                <SectionHeader>
                  <Text size="medium">Ajoneuvotiedot</Text>
                  <LinkButton onClick={handleVehicleInfoLinkClick}>ATJ-tiedot</LinkButton>
                </SectionHeader>
                <SectionBody>
                  {vehicleInfoCheck ? (
                    <>
                      <Text size="medium">{transformVehicleClass(vehicleInfoCheck.vehicle.vehicleClass)}</Text>
                      <Text size="medium">
                        {`${transformTransmission(vehicleInfoCheck.vehicle.transmission)} / ${transformDrive(
                          vehicleInfoCheck.vehicle.driveTrain
                        )} / ${transformFuel(vehicleInfoCheck.vehicle.fuel)}`}
                      </Text>
                    </>
                  ) : (
                    <Text size="medium">{towingOrder.vehicleDetails.vehicleClass}</Text>
                  )}
                </SectionBody>
              </SectionTextColumn>
              <SectionTextColumn width="100%">
                <SectionHeader>
                  <Text size="medium">Asiakas</Text>
                </SectionHeader>
                <SectionBody>
                  {towingOrder.orderer && towingOrder.orderer.__typename === 'PersonCustomer' && (
                    <Text size="medium">{`${towingOrder.orderer.firstName} ${towingOrder.orderer.lastName}`}</Text>
                  )}
                  {towingOrder.orderer && towingOrder.orderer.__typename === 'CompanyCustomer' && (
                    <Text size="medium">{`${towingOrder.orderer.companyName}`}</Text>
                  )}
                  {towingOrder.orderer && <PhoneNumber size="medium" phone={towingOrder.orderer.phone} />}
                </SectionBody>
              </SectionTextColumn>
              <SectionTextColumn width="100%">
                <SectionHeader>
                  <Text size="medium">{formatMessage(messages.from)}</Text>
                </SectionHeader>
                <SectionBody>
                  <Text size="medium">{parseAddress(towingOrder.from.address, towingOrder.from.city)}</Text>
                  <Text size="medium">{`${towingOrder.from.description}`}</Text>
                </SectionBody>
              </SectionTextColumn>
              <SectionTextColumn width="100%">
                <SectionHeader>
                  <Text size="medium">Mihin</Text>
                </SectionHeader>
                {repairShop &&
                towingOrder.typeIdentifier === TypeIdentifier.autoliitto &&
                towingOrder.jobDetails.serviceType !== 'towing' ? (
                  <SectionBody>
                    <Text size="medium" fontWeight="bold">
                      Jos työ muuttuu hinaukseksi:
                    </Text>
                    <Text size="medium">{parseAddress(repairShop.location.address, repairShop.location.city)}</Text>
                    <Text size="medium">{repairShop.name}</Text>
                  </SectionBody>
                ) : (
                  <SectionBody>
                    <Text size="medium">{parseAddress(towingOrder.to.address, towingOrder.to.city)}</Text>
                    <Text size="medium">{`${towingOrder.to.description}`}</Text>
                  </SectionBody>
                )}
              </SectionTextColumn>
              {buildJobDetails(towingOrder)}
              {towingOrder.typeIdentifier !== TypeIdentifier.sos && (
                <SectionTextColumn width="100%">
                  <SectionHeader>
                    <Text size="medium">Lisäselvitykset</Text>
                  </SectionHeader>
                  <SectionBody>
                    <Linkify>{toMultilineText(towingOrder.jobDetails.additionalInfo)}</Linkify>
                  </SectionBody>
                </SectionTextColumn>
              )}
              <SectionTextColumn width="100%">
                <SectionHeader>
                  <Text size="medium">Muistiinpanot</Text>
                </SectionHeader>
                <SectionBody>
                  <Linkify>{toMultilineText(towingOrder.jobDetails.notes)}</Linkify>
                </SectionBody>
              </SectionTextColumn>
            </Section>
          </Styling>
        </>
      )}
    </Page>
  )
}

export const ViewTowingOrderContent = injectIntl(ViewTowingOrderContentIntl)
